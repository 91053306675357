<template>
  <div class="div_title">
    <context :context=cate />
  </div>
  <div class="card" >
    <read page='blog'/>
  </div>
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</template>
<script>

import { ref, inject } from 'vue'
import context from '@/components/common/Context.vue'
import ssr from './components/common/ssr'
import read from '@/Read.vue'

export default {
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 블로그'
    const keywords = '꿀팁,팁,tip,블로그,콘텐츠,노하우,물류정보,직구정보,해외직구 노하우 및 해외직구하는 방법'
    const description = 'GEEK9에서 직접 작성한 직구의 모든 것!!'
    const geek9Url = ssr.GEEK9_URL + 'blog'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const expandedRows = ref({})
    const cate = ref([
      { label: '게시판', route:'https://contents.geek9.kr' },
      { label: '블로그', route: '/blog' }
    ])
    return {
      expandedRows, cate, addJsonLd
    }
  },
  components: {
    context, read
  },  
  mounted() {
    this.addJsonLd()
    this.api.setPage('blog')
    this.$emit("setMenu", {page:'blog'})
    console.log(this.api.getCookies('page'))
  },
  data() { 
    return { 
      blogKey:-1,
      blogKeyword:undefined,
      idx : '-1',
      page:1,
      blogList: Array(),
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.datatable-background {
  background-color:#f9f9f9;
}
</style>